import React, { useMemo, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { CAMPAIGNPRODUCTS, CAMPAIGNPRODUCTSFilterproduct } from "../../store/Compaign/Action";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useStateValue } from "../../store/state";
import Gray_Filter from "../../assets/images/purchase/Gray_Filter.png";
import campaignpage from "../../assets/images/campaign/Vector@3x.png"

const TOKEN = localStorage.getItem("token");
const COMPANYID = localStorage.getItem("companyid");
const USERID = localStorage.getItem("userid");

const STOREID = localStorage.getItem("storeid");
const CampaignSidebar = ({
  products,
  Id,
  currentPage,
  categoryId,
  currentSelection,
  setCurrentSelection,
  type,
}) => {
  const { t } = useTranslation();
  const [currentpage, setCurrentPage] = useState(1);
  const [applyFilter, setApplyFilter] = useState(false);
  const [showChildren, setShowChildren] = useState(null); // State
  const [mobileCategory, setMobileCategory] = useState("");
  const [{ filteredProductscampaign }, dispatch] = useStateValue();

  useEffect(() => {
    if (Id) {
      CAMPAIGNPRODUCTSFilterproduct(
        dispatch,

        TOKEN,
        Id,
        STOREID,
        categoryId
      );
    }
  }, []);
  const resetFilter = () => {
    setCurrentSelection((prevState) => ({
      ID: prevState.ID,
      subID: "",
    }));
  };

  const LEFTFILTERMEMO = useMemo(() => {
    return (
      <Accordion>
        <div className="category-heading">{t("category")}</div>
        <button
          className={`all_category ${
            (currentSelection?.ID === "" && currentSelection?.subID === "") ||
            Object.keys(currentSelection)?.length === 0
              ? "selectedFilter"
              : ""
          }`}
          onClick={() => {
            setCurrentPage(1);
            dispatch({ type: 'SET_CATEGORY' }); // Reset campaign products
            setCurrentSelection({ ID: "", subID: "" }); // Reset selection
            // Call the API to fetch all products
            let TOKEN = localStorage.getItem("token");
            let COMPANYID = localStorage.getItem("companyid");
            let USERID = localStorage.getItem("userid");
            let STOREID = localStorage.getItem("storeid");
            let COUNTRYDATA = "CountryX";
            let BRANDDATA = "BrandY";

            // Assuming you have access to the dispatch function and other necessary parameters
            CAMPAIGNPRODUCTS(
              dispatch,
              TOKEN,
              COMPANYID,
              STOREID,
              USERID,
              Id, // Ensure Id is available in this scope
              STOREID,
              categoryId, // Ensure categoryId is available in this scope
              "", // Pass empty strings for currentSelection
              currentpage, // Ensure currentpage is available in this scope
              BRANDDATA,
              COUNTRYDATA,
              "BrandY",
              "coffeeshop",
              "household",
              "sme"
            );
          }}
        >
          {t("All")}
        </button>
        {filteredProductscampaign.length ? (
          filteredProductscampaign.map((data, i) => (
            <Accordion.Item eventKey={i} key={i}>
              <Accordion.Header
                className={`${
                  currentSelection?.ID === data?.id && "selectedFilter"
                }`}
              >
                {t(data?.name)}
              </Accordion.Header>
              <Accordion.Body>
              <ul>
                {data?.children_data?.map(childdata => (
                    <li
                        className={`${currentSelection?.subID === childdata?.id && "selectedFilter"}`}
                        onClick={() => setCurrentSelection({ ID: data?.id, subID: childdata?.id })}
                        key={childdata?.id}
                    >
                        {t(childdata?.name)}
                    </li>
                ))}
            </ul>
              </Accordion.Body>
            </Accordion.Item>
          ))
        ) : (
          <div>No categories available</div>
        )}
      </Accordion>
    );
  }, [filteredProductscampaign, currentSelection, t]);

  const MOBILELEFTFILTERMEMO = useMemo(() => {
    return (
      <>
        {window.innerWidth <= 912 ? (
        
          <div className="PRODUCTPLP__MOBILE__SIDEBAR">
            <span
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasBottom"
              aria-controls="offcanvasBottom"
            >
              <img src={campaignpage}  style={{width:"20px", marginLeft:"12px"}} alt="" />
            </span>

            <div
              class="offcanvas offcanvas-bottom"
              tabindex="-1"
              id="offcanvasBottom"
              aria-labelledby="offcanvasBottomLabel"
            >
              <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasBottomLabel">
                  Filter By {filteredProductscampaign?.name}
                </h5>
                <button
                  type="button"
                  class="btn-close text-reset"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                ></button>
              </div>
              <div class="offcanvas-body small">
                  {mobileCategory?.children_data && (
                    <ul>
                      <li
                        className={`${
                          currentSelection?.subID ===
                            mobileCategory.children_data.id && "selectedFilter"
                        }`}
                        onClick={() => {
                          setCurrentSelection({
                            ID: mobileCategory?.id,
                            subID: mobileCategory.children_data.id,
                          });
                        }}
                      >
                        {t(mobileCategory.children_data.name)}
                      </li>
                    </ul>
                  )}

                <div className="offcanvas__buttons">
                  <button onClick={resetFilter} className="offcanvas__reset">
                    Reset
                  </button>
                  <button
                    onClick={() => setApplyFilter(true)}
                    className="offcanvas__apply"
                    data-bs-dismiss="offcanvas"
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
            <div className="PRODUCTPLP__MENU">
              <span
                className={`${mobileCategory === "" && "selectedMobileMenu"}`}
                onClick={() => {
                  setMobileCategory("");
                  setCurrentSelection({ ID: "", subID: "" });
                }}
              >
                {t("All")}
              </span>
              {filteredProductscampaign?.map((el, i) => (
                <span
                  className={`${
                    mobileCategory.id === el.id && "selectedMobileMenu"
                  }`}
                  onClick={() => {
                    setMobileCategory(el);
                    setCurrentSelection({ ID: el.id, subID: "" });
                  }}
                  key={i}
                >
                  {t(el?.name)}
                </span>
              ))}
            </div>
          </div>
        ) : null}
      </>
    );
  }, [filteredProductscampaign, currentSelection, showChildren, t]);

  return (
    <>
      {type === "web" ? (
        <div className="PRODUCTPLP__SIDEBAR">{LEFTFILTERMEMO}</div>
      ) : null}
      {type === "mobile" ? <div className="PRODUCTPLP__SIDEBAR">{MOBILELEFTFILTERMEMO}</div> : null}
    </>
  );
};

export default CampaignSidebar;
