const currenturl = window.location.origin;
let endpoint;
let imageendpoint;
let refendpoint;
let erpendpoint;
let financeendpoint;
let categoryIds;
let CDN_PRODUCT_BASE_URL;
let CDN_BASE_URL;
let CDN_PRODUCT_BASE_URL1;
let CDN_BASE_URL2;
let CDN_PRODUCT_CAMPAIGN;
let CDN_BASE_URL_FLAG;

if (currenturl == "https://shop.jagota.com") {
  endpoint = "https://magentoadmin.jagota.com/";
  imageendpoint = "https://d33rz15z17q3m0.cloudfront.net/";
  // imageendpoint = "https://magentoadmin.jagota.com/";
  refendpoint = "https://apis.jagota.com/Apip/ws_online_payment/check_payment";
  erpendpoint = "https://nodeapi.jagota.com";
  financeendpoint = "https://apis.jagota.com/Apip/ws_online_payment/get_payment_info";
  // No changes needed in the CDN link, it is correctly set:
  CDN_PRODUCT_BASE_URL = "https://d33rz15z17q3m0.cloudfront.net/media/catalog/product";
  CDN_PRODUCT_CAMPAIGN = "https://d33rz15z17q3m0.cloudfront.net/media/";
  CDN_BASE_URL="https://d33rz15z17q3m0.cloudfront.net/";
  CDN_BASE_URL2="https://d33rz15z17q3m0.cloudfront.net/media/";
  CDN_BASE_URL_FLAG="https://d33rz15z17q3m0.cloudfront.net/media/countryflags/"
// The CDN link is correctly set in the request file.
  categoryIds = {
    
    householdCategoryId: {
      ID:18,
      STOREID:1,
      categoryId:484,
    },
    coffeeCategoryId: {
      ID:19,
      STOREID:1,
      categoryId:484,
    },
    smeCategoryId: {
      ID:20,
      STOREID:1,
      categoryId:484,
    }
  }
} else {
  endpoint = "https://shop-admin-staging.jagota.com/";
  // imageendpoint = "https://jtbstaging.iksulalive.com/"
  imageendpoint = "https://d29embm0zls2iw.cloudfront.net/";
  refendpoint = "https://apis-staging.jagota.com/Apip/ws_online_payment/check_payment";
  erpendpoint = "https://nodeapi-staging.jagota.com";
  financeendpoint = "https://apis-staging.jagota.com/Apip/ws_online_payment/get_payment_info";
  CDN_PRODUCT_BASE_URL = "https://shop-admin-staging.jagota.com/media/catalog/product/";
  CDN_PRODUCT_CAMPAIGN ="https://shop-admin-staging.jagota.com/media/"
  CDN_BASE_URL="https://shop-admin-staging.jagota.com/";
  CDN_BASE_URL2="https://d33rz15z17q3m0.cloudfront.net/media/"; 
  CDN_BASE_URL_FLAG="https://shop-admin-staging.jagota.com/media/countryflags/"

  categoryIds = {
    householdCategoryId: {
      ID:18,
      STOREID:1,
      categoryId:484,
    },
    coffeeCategoryId: {
      ID:19,
      STOREID:1,
      categoryId:484,
    },
    smeCategoryId: {
      ID:20,
      STOREID:1,
      categoryId:484,
    }
  };
}

const URL = {
  BaseURL: `${endpoint}rest/V1`,
  ERP_URL: erpendpoint
};
const request = {
  //campaignpage
  householdCategoryId: categoryIds.householdCategoryId,
  coffeeCategoryId: categoryIds.coffeeCategoryId,
  smeCategoryId: categoryIds.smeCategoryId,
 //cdnurl
  CDN_PRODUCT_BASE_URL: CDN_PRODUCT_BASE_URL,
  CDN_BASE_URL:CDN_BASE_URL,
  CDN_BASE_URL2:CDN_BASE_URL2,
  CDN_PRODUCT_CAMPAIGN:CDN_PRODUCT_CAMPAIGN,
  CDN_BASE_URL_FLAG:CDN_BASE_URL_FLAG,
  // ERP
  ERPPDPORDERPERIOD: `${URL.ERP_URL}/products/get-product-order-period?`,
  ERPPDPSEASONS: `${URL.ERP_URL}/products/get-product-season?`,
  ERPHOLIDAYS: `${URL.ERP_URL}/company/company/holiday/`,
  // HOME PAGE
  CATALOGCONTENTS: `${URL?.BaseURL}/Jagota/Productslider/homepage-content-bottom/`,
  PROMOTIONCONTENT: `${URL?.BaseURL}/Jagota/Productslider/sidebar-additional-top/`,
  ARRIVALCONTENT: `${URL.BaseURL}/Jagota/Productsliderlist/`,
  MARQUEECONTENT: `${URL.BaseURL}/jagota/getHomePageNotification`,

  // CATALOG PAGE
  CATALOGPAGE: `${URL.BaseURL}/jagota/getLevelTwoCategory`,
  MOBILECATALOGPAGE: `${URL.BaseURL}/jagota/getMobileMenuCategories`,
  MOBILECATALOGTWO: `${URL.BaseURL}/jagota/getMobileLevelTwoCategory`,

  // PLP
  PRODUCTLEFTFILTER: `${URL.BaseURL}/jagota/getMyProductsCategory`,

  //CampaignProducts
  CAMPAIGNPRODUCTS: `${URL.BaseURL}/jagota/getCampaignProducts`,
  CAMPAIGNPRODUCTSFilter: `${URL.BaseURL}/jagota/getCampaignProductsCategory`,
  //search 
  SEARCHPAGEAPIGOBAL:`${URL.BaseURL}/jagota/getSearchTerms`,
  SEARCHSETTERMS: `${URL.BaseURL}/jagota/setSearchTerms`,
  // ORDER
  ORDERMAIN: `${URL.BaseURL}/jagota/orderslist`,

  QRPAYMENT: financeendpoint,
  INVOICEDATA: `${URL.ERP_URL}/invoice/get-invoice-details`,
  BILLINGINVOICE: `${URL.ERP_URL}/invoice/get-billing-details`,
  erplogin: `${URL?.ERP_URL}/login/b2b`,
  erprequest: `${URL?.ERP_URL}`,
  // image: endpoint,
  image: imageendpoint,

  // ADDRESS
  SEARCHADDRESS: `${URL.BaseURL}/jagota/getAddressFilter`,
  SUGGESTADDRESS: `${URL.BaseURL}/jagota/getAddressSearch`,
  GETALLBRANCH: `${URL.BaseURL}/jagota/customerAllBranch`,
  GETCOUNTRY: `${URL.BaseURL}/jagota/getcountry/`,
  GETREGION: `${URL.BaseURL}/jagota/getregion/`,
  GETPROVINCE: `${URL.BaseURL}/jagota/getprovince/`,
  GETDISTRICT: `${URL.BaseURL}/jagota/getdistrict/`,
  GETSUBDISTRICT: `${URL.BaseURL}/jagota/getsubDistrict/`,
  CREATEBRANCH: `${URL.BaseURL}/jagota/createBranch`,
  UPDATEBRANCH: `${URL.BaseURL}/jagota/updateBranch`,

  // PDP
  SIMILARPRODUCTS: `${URL.BaseURL}/jagota/getSimilarProduct`,

  megamenu: `${URL?.BaseURL}/jagota/getAllCategories`,
  adminLogin: `${URL.BaseURL}/integration/admin/token`,
  b2cRegister: `${URL.BaseURL}/customers/`,
  userLogin: `${URL.BaseURL}/jagota/customer/token`,
  userData: `${URL.BaseURL}/jagota/customers/me`,
  deletewishlist: `${URL.BaseURL}/jagota/removeWishlist`,
  addWishlist: `${URL.BaseURL}/jagota/addWishlist`,
  addFav: `${URL.BaseURL}/jagota/favouritesCreate`,
  recent: `${URL.BaseURL}/orders`,
  cardid: `${URL.BaseURL}/carts/mine`,
  cartadd: `${URL.BaseURL}/Jagota/addcart`,
  cartupdate: `${URL.BaseURL}/Jagota/itemUpdate`,
  carttotal: `${URL.BaseURL}/carts/mine/totals`,
  categorylist: `${URL.BaseURL}/categories`,
  shippingaddress: `${URL.BaseURL}/customers/me/shippingAddress`,
  billingaddress: `${URL.BaseURL}/customers/me/billingAddress`,
  billingaddressnew: `${URL.BaseURL}/jagota/Address`,
  deliverytimelist: `${URL.BaseURL}/jagota/getDeliveryTime`,
  createorder: `${URL.BaseURL}/jagota/orders`,
  brands: `${URL.BaseURL}/Jagota/createBrands`,
  mainslider: `${URL.BaseURL}/Jagota/createSlider`,
  promotionbanner: `${URL.BaseURL}/Jagota/createPromotionalslider`,
  recommendedbanner: `${URL.BaseURL}/Jagota/createRecommendedSliders`,
  catalogbanner: `${URL.BaseURL}/Jagota/createCatalogBanners`,
  bloglist: `${URL.BaseURL}/blogLists/`,
  companylist: `${URL.BaseURL}/Jagota/getCompanyList/`,
  store: `${URL.BaseURL}/Jagota/getStoreList/`,
  createquotation: `${URL.BaseURL}/jagota/createQuotation`,
  newlog: `${URL.BaseURL}/jagota/customerActivityCreate/`,
  createnotification: `${URL.BaseURL}/jagota/notificationCreate/`,
  createbranch: `${URL.BaseURL}/jagota/createBranch`,
  updatebranch: `${URL.BaseURL}/jagota/updateBranch`,
  deletebranch: `${URL.BaseURL}/jagota/deleteBranch`,
  profileimage: `${URL.BaseURL}/Jagota/profile/`,
  customerstatus: `${URL.BaseURL}/Jagota/statusUpdate`,
  addnewuser: `${URL.BaseURL}/jagota/customer/create`,
  updateuser: `${URL.BaseURL}/jagota/customer/update`,
  splitorderdelete: `${URL.BaseURL}/jagota/orderItemDelete`,
  deletecartitem: `${URL.BaseURL}/Jagota/cartDelete/`,
  productslider: `${URL.BaseURL}/Jagota/Productsliderlist`,
  fetchfav: `${URL.BaseURL}/jagota/getFavourites`,
  fetchrecent: `${URL.BaseURL}/jagota/recentOrder`,
  getbranch: `${URL.BaseURL}/jagota/customerIdBranch`,
  getCountry: `${URL.BaseURL}/jagota/getCountry`,
  getstate: `${URL.BaseURL}/jagota/getProvince`,
  getdistrict: `${URL.BaseURL}/jagota/getAmphur`,
  getsubdistrict: `${URL.BaseURL}/jagota/getTumbol`,
  updateprofile: `${URL.BaseURL}/awCaCompanyUser`,
  getactivitylogs: `${URL.BaseURL}/jagota/getCustomerActivity`,
  getcustomerdetails: `${URL.BaseURL}/jagota/customer/details`,
  getcompanyusers: `${URL.BaseURL}/awCaCompanyUser/allForCompany`,
  getcompanyroles: `${URL.BaseURL}/Jagota/rolesByCompanyId`,
  getimagebyid: `${URL.BaseURL}/jagota/customer/profileById`,
  getcompanybranch: `${URL.BaseURL}/jagota/companyBranch`,
  deletefav: `${URL.BaseURL}/jagota/deleteFavourites`,
  clearcart: `${URL.BaseURL}/jagota/deleteCart`,
  deletefromcart: `${URL.BaseURL}/Jagota/cartDelete`,
  getcatalogpdp: `${URL.BaseURL}/jagota/getProduct`,
  getrelatedproducts: `${URL.BaseURL}/Jagota/ReletedProducts`,
  getratingreview: `${URL.BaseURL}/Jagota/reviewlist`,
  thumbsupdown: `${URL.BaseURL}/Jagota/createHelpfulCount/`,
  catalogfilter: `${URL.BaseURL}/jagota/getCatalogProductsFilter`,
  getcart: `${URL.BaseURL}/Jagota/cartList`,
  getwishlist: `${URL.BaseURL}/jagota/getWishlist?customerId=`,
  createreviewrating: `${URL.BaseURL}/Jagota/createProductReview/`,
  changepassword: `${URL.BaseURL}/jagota/resetPassword`,
  search: `${URL.BaseURL}/jagota/autosearch`,
  getnotification: `${URL.BaseURL}/jagota/getCustomerNotification`,
  readnotification: `${URL.BaseURL}/jagota/readStatusChange`,
  allroles: `${URL.BaseURL}/awCaRole`,
  addressbasedorder: `${URL.BaseURL}/jagota/orderBasedAddress`,
  selectedorder: `${URL.BaseURL}/jagota/orderDetail`,
  getproductpdp: `${URL.BaseURL}/jagota/getMyProduct`,
  productplp: `${URL.BaseURL}/jagota/getMyProducts`,
  productplpfilter: `${URL.BaseURL}/jagota/getMyProductsFilter`,
  orderslist: `${URL.BaseURL}/jagota/orderslist`,
  orderdetails: `${URL.BaseURL}/jagota/orderdetails`,
  creditcheck: `${URL?.BaseURL}/jagota/getCredit`,
  promoslider: `${URL?.BaseURL}/Jagota/createPromotionalslider`,
  paymenttype: `${URL?.BaseURL}/jagota/getCustomerType`,
  maintenance: `${URL?.BaseURL}/jagota/getMaintainanceCheck`,
  reorder: `${URL.BaseURL}/jagota/reorder`,
  searchpageapi: `${URL?.BaseURL}/jagota/getSearchResultFilter`,
  searchpagecategory: `${URL?.BaseURL}/jagota/getSearchResultFilterCategory`,
  catpricefilter: `${URL?.BaseURL}/jagota/getFilterPrice`,
  invoicelist: `${URL?.BaseURL}/jagota/complaints/invoicelists`,
  complaintproducts: `${URL?.BaseURL}/jagota/complaints/getproducts`,
  savecomplaint: `${URL?.BaseURL}/jagota/complaints/savecomplaints`,
  parentorderid: `${URL?.BaseURL}/Jagota/getUniqueId`,
  changelang: `${URL?.BaseURL}/jagota/updateCustomerStore`,
  recommendedslider: `${URL?.BaseURL}/Jagota/createRecommendedSliders`,
  erpcustomerinfo: `${URL?.ERP_URL}/finance/get-customer-info?`,
  erpcustomerbilling: `${URL?.ERP_URL}/finance/get-customer-billing?`,
  erpcustomerinvoice: `${URL?.ERP_URL}/finance/get-customer-invoice-list?`,
  erpbillinginfo: `${URL?.ERP_URL}/finance/get-billing-list?`,
  erpbillinginvoicelist: `${URL?.ERP_URL}/finance/get-billing-invoice?`,
  complaintcheck: `${URL?.BaseURL}/jagota/complaints/complaintCheck/?order_id=`,
  erppaymenthistory: `${URL?.ERP_URL}/finance/get-payment-history?`,
  quoteitemupdate: `${URL?.BaseURL}/Jagota/quoteItemUpdate`,
  catalogpricefilter: `${URL?.BaseURL}/jagota/getCatalogProductsFilterPrice`,
  myproductpricefilter: `${URL?.BaseURL}/jagota/getMyProductsFilterPrice`,
  catalogcountry: `${URL?.BaseURL}/jagota/getCatalogProductsFilterCountry`,
  productcountry: `${URL?.BaseURL}/jagota/getMyProductsFilterCountry`,
  searchcountry: `${URL?.BaseURL}/jagota/getFilterCountry`,
  productcathighlight: `${URL?.BaseURL}/jagota/getMyProductsFilterCategory`,
  erpgetcountry: `${URL?.ERP_URL}/branch/get-country?custCode=`,
  erpgetstate: `${URL?.ERP_URL}/branch/get-region?custCode=`,
  erpgetdistrict: `${URL?.ERP_URL}/branch/get-province?custCode=`,
  erpsubdistrict: `${URL?.ERP_URL}/branch/get-amphur`,
  erppincode: `${URL?.ERP_URL}/branch/get-tumbol`,
  promolist: `${URL?.BaseURL}/Jagota/getPromotion/`,
  promodetails: `${URL?.BaseURL}/Jagota/getPromotionDetails/`,
  promoclose: `${URL?.BaseURL}/Jagota/setPromotionStatus/`,
  getTerms: `${URL?.BaseURL}/Jagota/getTermsConditionStatus/`,
  setTerms: `${URL?.BaseURL}/Jagota/setTermsConditionStatus/`,
  logTerms: `${URL?.BaseURL}/jagota/termsConditionLogCreate`,
  getcookies: `${URL?.BaseURL}/Jagota/getCookiesStatus/`,
  setcookies: `${URL?.BaseURL}/Jagota/setCookiesStatus/`,
  getcookiescms: `${URL?.BaseURL}/cmsPage/`,
  getcms: `${URL?.BaseURL}/cmsPage/`,
  generalFIlter: `${URL?.BaseURL}/jagota/getSubCategoryFilterList`,
  brandFilterCategory: `${URL?.BaseURL}/jagota/getCatalogProductsFilterBrand/`,
  // brandFilterMyProduct: `${URL?.BaseURL}/jagota/getMyProductsFilterBrand/`,
  brandFilterSearch: `${URL?.BaseURL}/jagota/getFilterBrand/`,
  productTypeMyProduct: `${URL?.BaseURL}/jagota/getMyProductsFilterProductType/`,
  productTypeCatalog: `${URL?.BaseURL}/jagota/getCatalogProductsFilterProductType/`,
  productTypeSearch: `${URL?.BaseURL}/jagota/getFilterProductType/`,
  forgotPassword: `${URL?.BaseURL}/jagota/forgotpassword`,
  forgotPasswordReset: `${URL?.BaseURL}/jagota/passwordReset`,
  stockcheck: `${URL?.BaseURL}/jagota/qtyAvailablityCheck`,
  creditrequest: `${URL?.ERP_URL}/finance/set-credit-request`
};

export default request;
