import axios from "axios";
import request from "../../request";

// MAIN BANNER API
export const MAINBANNERCONTENT = (dispatch) => {
    async function mainbannercontent() {
        try {
            const data = await axios({
                method: "get",
                url: request?.recommendedslider,
            });
            dispatch({
                type: 'MAIN__BANNER__CONTENTS',
                payload: data?.data
            })
            data?.data?.map((value) => {
                if (value.image == undefined) return
                const link = document.createElement('link');
                link.rel = 'preload';
                link.href = `${request.image}${value.image}`;
                link.as = 'image';
                document.head.appendChild(link);
            })
        } catch (e) {
            console.log(e)
        }
    }
    mainbannercontent()
}

// TRENDING PRODUCTS API
export const TRENDINGCONTENT = (dispatch, userid, storeid) => {
    let TOKEN = localStorage.getItem('token')
    async function trendingcontent() {
        try {
            const data = await axios({
                method: "get",
                url: `${request.ARRIVALCONTENT}12/${storeid}/${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            dispatch({
                type: 'TRENDING__CONTENTS',
                payload: data?.data
            })
            data.data[0]?.products?.forEach((value) => {
                if (value.image == undefined) return;
                const link = document.createElement('link');
                link.rel = 'preload';
                link.href = `${request.image}media/catalog/product${value.image}`;
                link.as = 'image';
                document.head.appendChild(link);
            });
        } catch (e) {
            console.log(e)
        }
    }
    trendingcontent()
}

// NEW ARRIVAL PRODUCTS API
export const ARRIVALCONTENT = (dispatch, userid, storeid) => {
    let TOKEN = localStorage.getItem('token')
    async function arrivalcontent() {
        try {
            const data = await axios({
                method: 'get',
                url: `${request.ARRIVALCONTENT}13/${storeid}/${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            })
            dispatch({
                type: 'ARRIVAL__CONTENTS',
                payload: data?.data
            })
            data?.data[0]?.products?.filter(value => {
                if (value.image === undefined) return
                const link = document.createElement('link');
                link.rel = 'preload';
                link.href = `${request.image}media/catalog/product${value.image}`;
                link.as = 'image';
                document.head.appendChild(link);
            })
        } catch (e) {
            console.log(e)
        }
    }
    arrivalcontent()
}

// PROMOTION CONTENT API
export const PROMOTIONCONTENT = (dispatch, userid, storeid) => {
    let TOKEN = localStorage.getItem('token')
    async function promotioncontent() {
        try {
            const data = await axios({
                method: 'get',
                url: `${request.PROMOTIONCONTENT}${storeid}/${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            })
            dispatch({
                type: 'PROMOTION__CONTENTS',
                payload: data?.data
            })
            data?.data?.filter(D => {
                D?.products?.map((value) => {
                    if (value.image === undefined) return
                    const link = document.createElement('link');
                    link.rel = 'preload';
                    link.href = `${request.image}media/catalog/product${value.image}`;
                    link.as = 'image';
                    document.head.appendChild(link);
                })
                if (D?.mainImage != undefined) {
                    const link1 = document.createElement('link');
                    link1.rel = 'preload';
                    link1.href = `${request.image}media/${D.mainImage}`;
                    link1.as = 'image';
                    document.head.appendChild(link1);
                }
                if (D?.responsiveImage != undefined) {
                    const link2 = document.createElement('link');
                    link2.rel = 'preload';
                    link2.href = `${request.image}media/${D.responsiveImage}`;
                    link2.as = 'image';
                    document.head.appendChild(link2);
                }
            })
        } catch (e) {
            console.log(e)
        }
    }
    promotioncontent()
}

//OUR CATAGEORY API

export const FETCH_PROMOTION_BANNER = () => {
    return async (dispatch) => {
      let TOKEN = localStorage.getItem('token');
  
      if (!TOKEN) {
        console.error("No token found in localStorage");
        return;
      }
      try {
        const response = await axios.get(request.promotionbanner, {
          headers: {
            "Authorization": `Bearer ${TOKEN}`,
          },
        });
        dispatch({
          type: 'PROMOTION_BANNER_DATA',
          payload: response.data, // Assuming response.data is an array of promotion data
        });
      } catch (error) {
        if (error.response) {
          console.error("API response error:", error.response.data);
        } else {
          console.error("An error occurred:", error.message);
        }
      }
    };
  };
// CATALOG CONTENTS API
export const CATALOGCONTENTS = (dispatch, userid, storeid) => {
    let TOKEN = localStorage.getItem('token')
    async function catalogContents() {
        try {
            const data = await axios({
                method: 'get',
                url: `${request.CATALOGCONTENTS}${storeid}/${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            })
            dispatch({
                type: 'CATALOG__CONTENTS',
                payload: data?.data
            })
            data?.data?.filter(D => {
                if (D?.mainImage != undefined) {
                    link1.rel = 'preload';
                    link1.href = `${request.image}media/${D.mainImage}`;
                    link1.as = 'image';
                    document.head.appendChild(link1);
                    const link1 = document.createElement('link');
                }
                if (D?.responsiveImage != undefined) {
                    const link2 = document.createElement('link');
                    link2.rel = 'preload';
                    link2.href = `${request.image}media/${D.responsiveImage}`;
                    link2.as = 'image';
                    document.head.appendChild(link2);
                }
                D?.products?.map((value) => {
                    if (value.image === undefined) return
                    const link = document.createElement('link');
                    link.rel = 'preload';
                    link.href = `${request.image}media/catalog/product${value.image}`;
                    link.as = 'image';
                    document.head.appendChild(link);
                })
            })
        } catch (e) {
            console.log(e)
        }
    }
    catalogContents()
}

// BRAND CONTENT API
export const BRANDSCONTENT = (dispatch) => {
    async function brandcontent() {
        try {
            const config = {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            };
            const response = await axios.get(request.brands, config);
            const brandsData = response.data;
            
            dispatch({
                type: 'BRAND__CONTENTS',
                payload: brandsData
            })

            if (brandsData.length > 0) {
                brandsData.forEach((value) => {
                    if (value.image != undefined) {
                        const link = document.createElement('link');
                        link.rel = 'preload';
                        link.href = `${request.image}${value.image}`;
                        link.as = 'image';
                        document.head.appendChild(link);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
    brandcontent();
}

// PROMOTION POPUP CONTENT API
export const PROMOTIONPOPUP = (dispatch, userid) => {
    let TOKEN = localStorage.getItem('token')
    async function promotionpopup() {
        try {
            const data = await axios({
                method: 'get',
                url: `${request?.promolist}${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            dispatch({
                type: 'PROMOTION__POPUP',
                payload: data?.data
            })
        } catch (e) {
            return;
        }
    }
    promotionpopup()
}

// PROMOTION POPUP CONTENT CLOSING/ACCEPTING API
export const PROMOTIONCLOSEPOPUP = (dispatch, userid) => {
    let TOKEN = localStorage.getItem('token')
    async function promotionclosepopup() {
        try {
            await axios({
                method: 'post',
                url: `${request?.promoclose}${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            dispatch({
                type: 'PROMOTION__POPUP__CLOSE',
                payload: false
            })
        } catch (e) {
            dispatch({
                type: 'PROMOTION__POPUP__CLOSE',
                payload: false
            })
        }
    }
    promotionclosepopup()
}

// API TO GET THE CATEGORY ID AND ROUTE THE PAGE TO THAT CATALOG PAGE
export const PROMOTIONBASEDCATEGORYROUTING = (dispatch, userid, storeid, history, value) => {
    let TOKEN = localStorage.getItem('token')
    async function promocategoryrouting() {
        try {
            const promodetails = await axios({
                method: 'get',
                url: `${request?.promodetails}${value}/${storeid}/${userid}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            if (promodetails?.data[0]?.details?.category_id) {
                history.push(`/catalog/${promodetails?.data[0]?.details?.category_id}`);
            }
        } catch (e) {
            console.log(e);
        }
    }
    promocategoryrouting()
}