import React, { useCallback, useEffect, useRef, useState } from 'react';
import './MCatalog.scss';
import { MOBILECATALOG, MOBILECATALOGTWO } from '../../store/Catalog/Action';
import { useStateValue } from '../../store/state';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { BRANDFILTER, COUNTRYFILTER, GENERALFILTER, GETMOBILECATALOGPLP } from '../../store/PLP/Action';
import request from '../../request';
import InfiniteScroll from 'react-infinite-scroll-component';
import MCART from '../../assets/images/catalog/Mobile/MCART.svg'
import { ADDTOCART } from '../../store/Cart/Action';
import { toast } from 'react-toastify';
import search from "../../assets/images/catalog/search.png";
import filter_list from "../../assets/images/catalog/filter_list.png";
import NewCardSkeleton from '../../components/Skeleton/NewCardSkeleton/NewCardSkeleton';
import snowImg from "../../assets/images/snow-img.svg";
import quotationImg from "../../assets/images/Quotation.png";
import chilledImg from "../../assets/images/Chilled.svg";
import FilterComponent from '../../components/Filters/FIltersPopup';

function MCatalog() {
  const [{ MCATALOG__DATA__LIST, SEARCH__TEXT__DATA, MCATALOG__DATA__TWO__LIST, CATALOG_BRAND_FILTER_DATA, CATALOG_COUNTRY_FILTER_DATA, GENERAL_FILTER_DATA, CATALOGPLPDATA, CATALOGPLPDATA11, cart, GETMOBILECATALOGPL, }, dispatch] = useStateValue();
  const [level1, setLevel1] = useState([]);
  const [selectedLevel1, setSelectedLevel1] = useState([]);
  const [selectedLevel2, setSelectedLevel2] = useState([]);
  const [selectedLevel3, setSelectedLevel3] = useState([]);
  const [selectedLevel4, setSelectedLevel4] = useState([]);
  const [selectedLevel5, setSelectedLevel5] = useState([]);
  const [selectedLevel6, setSelectedLevel6] = useState([]);
  const [loader, setLoader] = useState(false);
  const [autoLoader, setAutoLoader] = useState(1);
  const { t } = useTranslation();
  const [PLPData, setPLPData] = useState([]);
  const { type } = useParams();
  const level1Ref = useRef(null);
  const [selectedFilter, setSelectedFilter] = useState('');
  const [country, setCountry] = useState([]);
  const [brand, setBrand] = useState([]);
  const [refreshAPI, setRefreshAPI] = useState(false);
  const location = useLocation();
  const [searchVisible, setSearchVisible] = useState(false);

  const TOKEN = localStorage.getItem('token');
  const COMPANYID = localStorage.getItem('companyid');
  const USERID = localStorage.getItem('userid');
  const STOREID = localStorage.getItem('storeid');

  const toggleSearch = () => {
    setSearchVisible(!searchVisible);
  };

  const loadNextPage = () => {
    setAutoLoader(prevAutoLoader => prevAutoLoader + 1);
  };

  useEffect(() => {
    setLoader(true)
    setPLPData([])
    setAutoLoader(1)
  }, [selectedLevel1, selectedLevel2, selectedLevel3, selectedLevel4, selectedLevel5, selectedLevel6])

  useEffect(() => {
    // if (PLPData?.length > 0 && PLPData?.length < 20 && autoLoader == 1) return;
    // CATALOGPLPDATA?.products?.filter(data => (
    //   setPLPData(prevState => [
    //     ...prevState,
    //     data
    //   ])
    // ))

    if (autoLoader === 1) {
      if (CATALOGPLPDATA?.products) {
        setPLPData(CATALOGPLPDATA?.products);
      }
    } else {
      CATALOGPLPDATA?.products?.filter(data => (
        setPLPData(prevState => [
          ...prevState,
          data
        ])
      ))
    }
  }, [CATALOGPLPDATA]);

  // API call - for get first level category meat
  useEffect(() => {
    MOBILECATALOG(dispatch);
  }, []);

  // API call here - get second level category - beef
  useEffect(() => {
    if (MCATALOG__DATA__LIST?.length) {
      const sortedLevel1 = MCATALOG__DATA__LIST
        .filter(item => parseInt(item?.sort_order) > 0)
      setLevel1(sortedLevel1);

      if (type) {
        let routedlevel1 = sortedLevel1?.find(el => el?.id === type)
        if (routedlevel1) {
          setSelectedLevel1(routedlevel1)
          MOBILECATALOGTWO(dispatch, routedlevel1)
        }
      } else {
        if (sortedLevel1?.length) {
          MOBILECATALOGTWO(dispatch, sortedLevel1[0])
          setSelectedLevel1(sortedLevel1[0])
        }
      }
    }
  }, [MCATALOG__DATA__LIST, type]);

  // API call here - get 3 level category list
  useEffect(() => {
    if (MCATALOG__DATA__TWO__LIST?.length) {
      // setSelectedLevel2(MCATALOG__DATA__TWO__LIST[0]);
      GENERALFILTER(dispatch, MCATALOG__DATA__TWO__LIST?.[0]?.id);
      // setSelectedLevel3([]);
      // setSelectedLevel4([]);
      // setSelectedLevel5([]);
      // setSelectedLevel6([]);
    } else {
      // setSelectedLevel2([]);
      // setSelectedLevel3([]);
      // setSelectedLevel4([]);
      // setSelectedLevel5([]);
      // setSelectedLevel6([]);
    }
  }, [MCATALOG__DATA__TWO__LIST]);

  useEffect(() => {
    // setSelectedLevel3([])
  }, [selectedLevel2])

  useEffect(() => {
    // setSelectedLevel4([])
  }, [selectedLevel3]);

  useEffect(() => {
    // setSelectedLevel5([])
  }, [selectedLevel4]);

  useEffect(() => {
    // setSelectedLevel6([])
  }, [selectedLevel5]);

  // useEffect(() => {
  //   if (GENERAL_FILTER_DATA?.length) {
  //     setSelectedLevel3(GENERAL_FILTER_DATA[0]);
  //   }
  // }, [GENERAL_FILTER_DATA]);

  // API call here - get meat, beef, aus product list
  useEffect(() => {
    let ID = selectedLevel1?.id ? selectedLevel1?.id : "";
    let SUBID = selectedLevel2?.id ? selectedLevel2?.id : "";
    let VARIETY = selectedLevel3?.id ? selectedLevel3?.id : "";
    let A1 = selectedLevel4?.id ? selectedLevel4?.id : "";
    let A2 = selectedLevel5?.id ? selectedLevel5?.id : "";
    let A3 = selectedLevel6?.id ? selectedLevel6?.id : "";
    let COUNTRYDATA = country;
    let BRANDDATA = brand;
    if (!ID) return;
    GETMOBILECATALOGPLP(dispatch, TOKEN, COMPANYID, USERID, STOREID, SUBID, ID, BRANDDATA, COUNTRYDATA, VARIETY, A1, A2, A3, setLoader, autoLoader, CATALOGPLPDATA11, CATALOGPLPDATA)
  }, [brand, country, selectedLevel1?.id, selectedLevel2?.id, selectedLevel3?.id, selectedLevel4?.id, selectedLevel5?.id, selectedLevel6?.id, autoLoader])

  // API call here -> get brand API , get country API - sometime typeid -> undefine
  useEffect(() => {
    let ID = selectedLevel2?.id || selectedLevel1?.id;
    let SUBID = selectedLevel3?.id || "";
    if (!ID) return
    let COUNTRYDATA = country?.length ? country : "";
    let BRANDDATA = brand?.length ? brand : ""
    handleReset()
    BRANDFILTER(dispatch, TOKEN, COMPANYID, USERID, STOREID, SUBID, ID, BRANDDATA, COUNTRYDATA)
    COUNTRYFILTER(dispatch, TOKEN, COMPANYID, USERID, STOREID, SUBID, ID, BRANDDATA, COUNTRYDATA)
  }, [selectedLevel2, selectedLevel3, localStorage.getItem('storeid'), refreshAPI, location.pathname])

  const chooseLevel1 = (el) => {
    setLoader(true);
    MOBILECATALOGTWO(dispatch, el)
    setSelectedLevel1(el)
    setSelectedLevel2([])
    // setSelectedLevel3([])
    // setSelectedLevel4([])
    // setSelectedLevel5([])
    // setSelectedLevel6([])
  }

  // API call here - get 3 level category list
  const chooseLevel2 = (el) => {
    setLoader(true);
    setSelectedLevel2(el)
    GENERALFILTER(dispatch, el?.id)
    setSelectedLevel3([])
    // setSelectedLevel4([])
    // setSelectedLevel5([])
    // setSelectedLevel6([])
  }

  const chooseLevel3 = (el) => {
    setLoader(true);
    setSelectedLevel3(el)
    // setSelectedLevel4([])
    // setSelectedLevel5([])
    // setSelectedLevel6([])
  }

  const chooseLevel4 = (el) => {
    setSelectedLevel4(el)
    // setSelectedLevel5([])
    // setSelectedLevel6([])
  }

  const chooseLevel5 = (el) => {
    setSelectedLevel5(el)
    // setSelectedLevel6([])
  }

  const chooseLevel6 = (el) => {
    setSelectedLevel6(el)
  }

  function formatToCurrency(price) {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const replacements = {
    "KG": t('KG'),
    "PC": t('PC'),
    "PK": t('PK'),
    "BG": t("BG"),
    "BK": t("BK"),
    "BT": t("BT"),
    "BX": t("BX"),
    "CA": t("CA"),
    "CP": t("CP"),
    "CT": t("CT"),
    "DR": t("DR"),
    "DZ": t("DZ"),
    "GL": t("GL"),
    "GM": t("GM"),
    "JR": t("JR"),
    "LT": t("LT"),
    "ML": t("ML"),
    "OZ": t("OZ"),
    "PA": t("PA"),
    "SC": t("SC"),
    "SH": t("SH"),
    "TI": t("TI"),
    "TR": t("TR"),
    "TU": t("TU"),
    "TY": t("TY"),
  };

  const updatedData = (item => {
    let updatedItem = item;
    Object.keys(replacements).forEach(key => {
      updatedItem = updatedItem.replace(key, replacements[key]);
    });

    return updatedItem;
  });

  const addtocart = async (value) => {
    if (value?.is_in_stock == "0") return
    ADDTOCART(dispatch, value, cart, toast)
  };

  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
    // handleReset();
  };

  const handleBrandChange = (data) => {
    const brandId = data;
    setBrand((prev) =>
      prev.includes(brandId)
        ? prev.filter((short_code) => short_code !== brandId)
        : [...prev, brandId]
    );
    setAutoLoader(1);
  };

  const handleCountryChange = (data) => {
    const brandId = data;
    setCountry((prev) =>
      prev.includes(brandId)
        ? prev.filter((short_code) => short_code !== brandId)
        : [...prev, brandId]
    );
    setAutoLoader(1);
  };

  const handleReset = () => {
    setAutoLoader(1)
    setBrand([]);
    setCountry([]);
  };

  const renderOffcanvasContent = useCallback(() => {
    switch (selectedFilter) {
      case 'Brand':
        return (
          <FilterComponent
            selectedBrandData={brand}
            CATALOGPLPDATA={CATALOGPLPDATA}
            brandFilterData={CATALOG_BRAND_FILTER_DATA}
            handleBrandChange={(id) => {
              handleBrandChange(id);
            }}
            reset={handleReset}
            type="Brand"
          />
        );
      case 'Country':
        return (
          <FilterComponent
            selectedCountryData={country}
            CATALOGPLPDATA={CATALOGPLPDATA}
            countryFilterData={CATALOG_COUNTRY_FILTER_DATA}
            handleCountryChange={handleCountryChange}
            reset={handleReset}
            type="Country"
          />
        );
      case 'Filter':        // Combine both brand and country filters
        return (
          <FilterComponent
            selectedData={country.concat(brand)}  // Combining selected data for simplicity
            selectedCountryData={country}
            selectedBrandData={brand}
            CATALOGPLPDATA={CATALOGPLPDATA}
            countryFilterData={CATALOG_COUNTRY_FILTER_DATA}
            brandFilterData={CATALOG_BRAND_FILTER_DATA}
            handleCountryChange={handleCountryChange}
            handleBrandChange={(id) => {
              handleBrandChange(id);
            }}
            reset={handleReset}
            type="Filter"
          />);
      default:
        return null;
    }
  });

  const backgroundColor = selectedLevel1?.bgcolor || "";
  const color = selectedLevel1?.color || "";

  return (
    <div className='MCATALOG'>
      <div className='MCATALOG__SEARCH'>
        <label>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <g clip-path="url(#clip0_2404_5813)">
              <path d="M15.875 14H15.085L14.805 13.73C15.785 12.59 16.375 11.11 16.375 9.5C16.375 5.91 13.465 3 9.875 3C6.285 3 3.375 5.91 3.375 9.5C3.375 13.09 6.285 16 9.875 16C11.485 16 12.965 15.41 14.105 14.43L14.375 14.71V15.5L19.375 20.49L20.865 19L15.875 14ZM9.875 14C7.385 14 5.375 11.99 5.375 9.5C5.375 7.01 7.385 5 9.875 5C12.365 5 14.375 7.01 14.375 9.5C14.375 11.99 12.365 14 9.875 14Z" fill="#1C304A" fill-opacity="0.52" />
            </g>
            <defs>
              <clipPath id="clip0_2404_5813">
                <rect width="24" height="24" fill="white" transform="translate(0.375)" />
              </clipPath>
            </defs>
          </svg>
          <Link to="/searchSuggest">
            <input type='search' placeholder={`${t("Search Product, Brand")}`} />
          </Link>
        </label>
      </div>
      <div className='MCATALOG__LEVEL1' ref={level1Ref} style={{ backgroundColor: backgroundColor }}>
        {window.innerWidth <= 912 &&
          <div className='MCATALOG__LEVEL1_HEADING'>
            <div className='HEADING'>{t('CATEGORY')}</div>
            <Link to="/searchSuggest">
              <div className='SEARCH_ICON'><img src={search} alt="" onClick={toggleSearch} /></div>
            </Link>
          </div>
        }
        <ul>
          {level1?.map((el, i) => (
            <li
              className={`${selectedLevel1?.id === el?.id && "selectedlevelone"} ${selectedLevel1?.id === el.id ? "zoomImage" : ""}`}
              onClick={() => chooseLevel1(el)}
              key={i}
              style={selectedLevel1?.id === el?.id ? { borderBottom: "4px solid " + color } : {}}
            >
              <img src={`${request.image}${el.image}`} alt="" />
              <span style={selectedLevel1?.id === el?.id ? { color: color } : {}}>{t(el?.Name)}</span>
              <hr></hr>
            </li>
          ))}
        </ul>
      </div>
      <div className={`MCATALOG__BODY`}>
        <div className='MCATALOG__LEVEL2'>
          <ul>
            {MCATALOG__DATA__TWO__LIST?.map((el, i) => (
              <li
                onClick={() => chooseLevel2(el)}
                className={`${selectedLevel2?.id === el?.id && "selectedleveltwo"}`}
                key={i}>{t(el?.name)}</li>
            ))}
          </ul>
        </div>
        <div className='MCATALOG__PRODUCTS'>
          <div className='MCATALOG__CONTENT'>
            {GENERAL_FILTER_DATA?.length ?
              <ul>
                {selectedLevel2?.id && GENERAL_FILTER_DATA?.map((el, i) => (
                  <li key={i} className={`${selectedLevel3?.id === el?.id && "selectedlevelthree"}`}
                    onClick={() => chooseLevel3(el)}
                  >
                    {t(el?.name)}
                  </li>))}
              </ul> : ""}

            {/* {selectedLevel2?.id && GENERAL_FILTER_DATA?.length ? <hr /> : ""} */}

            {/* Need to confirm JN */}
            {/* {selectedLevel3?.children_data?.length ? 
               <ul>
                 {selectedLevel3?.children_data?.map((el, i) => (
                   <li
                     className={`${selectedLevel4?.id === el?.id && "selectedlevelfour"}`}
                     onClick={() => chooseLevel4(el)}
                     key={i}
                   >
                     {t(el?.name)}
                   </li>
                 ))}
               </ul>
               : ""
                }

                {selectedLevel4?.children_data?.length ?
               <ul>
                 {selectedLevel4?.children_data?.map((el, i) => (
                   <li
                     className={`${selectedLevel5?.id === el?.id && "selectedlevelfive"}`}
                     onClick={() => chooseLevel5(el)}
                     key={i}
                   >
                     {t(el?.name)}
                   </li>
                 ))}
               </ul>
               : ""
                 }

               {selectedLevel5?.children_data?.length ?
               <ul>
                 {selectedLevel5?.children_data?.map((el, i) => (
                   <li
                     className={`${selectedLevel6?.id === el?.id && "selectedlevelfive"}`}
                     onClick={() => chooseLevel6(el)}
                     key={i}
                   >
                     {t(el?.name)}
                   </li>
                 ))}
               </ul>
               : ""
               } */}


            {
              window.innerWidth <= 912 &&
              <>
                <div className=' MCATALOG_FILTER'>
                  <div className='MCATALOG_FILTER_col_1'>
                    <div data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom"
                      onClick={() => handleFilterClick('Brand')}>
                      {t("Brand")}  <i className="fas fa-caret-down"></i>
                    </div>
                    <div data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom"
                      onClick={() => handleFilterClick('Country')}>
                      {t("Country")}  <i className="fas fa-caret-down"></i>
                    </div>
                  </div>
                  <div className='MCATALOG_FILTER_col_2' data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom"
                    onClick={() => handleFilterClick('Filter')}>
                    <img src={filter_list} alt="" />
                    <span>{t("Filter")}</span>
                  </div>
                  {/* Corresponding filter content show here */}
                  {(selectedFilter && selectedFilter != '') ? renderOffcanvasContent() : null}
                </div>
              </>
            }
          </div>
          <div className="MCATALOG__LISTS">
            <InfiniteScroll
              dataLength={PLPData?.length || 0}
              next={loadNextPage}
              hasMore={autoLoader * 10 < CATALOGPLPDATA?.product_count}
              loader={loader && [...Array(10)].map((_, index) => <NewCardSkeleton key={index} />)}
              scrollThreshold={0.5}
            >
              {!loader && PLPData?.length === 0 ? ( // Check if PLPData is empty
                <p>{t("NOPRODUCTAVAILABLE")}</p>
              ) : (
                PLPData?.map((el, i) => (
                  <div className="MCATALOG__LIST" key={i}>
                    <div className="MCATALOG__LIST__IMG">
                      <Link to={`/pdp/${!el?.quote ? "catalog" : "product"}/${el?.order_taking == "Y" ? "ordertaking" : "stock"
                        }/${el?.category_id}/${el?.entity_id}/${el?.url_key}`}>
                        <img src={`${request?.image}media/catalog/product${el?.image}`} alt="" />
                      </Link>
                      {/* {el?.order_taking == "Y" && (<span className="MCATALOG__PREORDER">{t("PREORDER")}</span>)} */}
                    </div>
                    <div className="MCATALOG__LIST__DETAIL">
                      <span className="MCATALOG__NAME" title={el?.name}>{el?.name}</span>
                      <span className="MCATALOG__TYPE">
                        {el?.order_taking == "Y" && (<span className="MCATALOG__PREORDER">{t("PREORDER")}</span>)}
                        {(el?.storage || '').startsWith('FROZEN') && <img src={snowImg} className="snow-img" />}
                        {(el?.storage || '').startsWith('CHILLED') && <img src={chilledImg} className="snow-img" />}
                      </span>
                      <span className="MCATALOG__SIZE">{t("SIZE")} {el?.pack_size}</span>
                      <div className='MCATALOG__LAST_ROW'>
                        {el?.quote && <span className='MCATALOG__QUOTATION__PRICE' >
                          <img src={quotationImg} alt="" className='QUOTES' />
                        </span>}
                        <span className="MCATALOG__PRICE">
                          <span className='MCATALOG__PRICE_SYMBOL'>฿{" "}</span>
                          {formatToCurrency(
                            parseFloat(el?.selling_unit_price).toFixed(2)
                          )}/
                          <span className="MCATALOG__UNIT">
                            {t(updatedData(el?.selling_unit))}
                          </span>
                          {el?.order_taking == "Y" ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              className="MCATALOG__LIST__CART">
                              <g clip-path="url(#clip0_1972_38528)">
                                <rect width="28" height="28" rx="14" fill="#37BFA7" />
                                <path d="M10.1111 13.2H11.6667V14.8H10.1111V13.2ZM21 9.2V20.4C21 21.28 20.3 22 19.4444 22H8.55556C7.69222 22 7 21.28 7 20.4L7.00778 9.2C7.00778 8.32 7.69222 7.6 8.55556 7.6H9.33333V6H10.8889V7.6H17.1111V6H18.6667V7.6H19.4444C20.3 7.6 21 8.32 21 9.2ZM8.55556 10.8H19.4444V9.2H8.55556V10.8ZM19.4444 20.4V12.4H8.55556V20.4H19.4444ZM16.3333 14.8H17.8889V13.2H16.3333V14.8ZM13.2222 14.8H14.7778V13.2H13.2222V14.8Z"
                                  fill="white" />
                              </g>
                              <defs>
                                <clipPath id="clip0_1972_38528">
                                  <rect width="28" height="28" rx="14" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          ) : (
                            <img onClick={() => addtocart(el)} src={MCART} alt=""
                              className={`MCATALOG__LIST__CART ${el?.is_in_stock == "0" && "MCATALOG__NOSTOCK"}`} />
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </InfiniteScroll>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MCatalog;
