import React, { useEffect, useMemo, useState } from "react";
import "./OrderPage.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import DateRangePicker from "../../components/Finance/FinanceTable/DateRangePicker";
import MainBlock from "../../components/OrderPage/MainBlock/MainBlock";
import { ORDERMAIN } from "../../store/Order/Action";
import { useStateValue } from "../../store/state";
import Slider from "react-slick";
import DatePicker from "react-datepicker";
import moment from "moment";

function OrderPage() {
  const [selectedTab, setSelectedTab] = useState("All Orders");
  const [searchOrder, setSearchOrder] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(moment().subtract(1, "week").format("YYYY-MM-DD"))
  );
  const [tempFromDate, setTempFromDate] = useState(null);
  const [tempToDate, setTempToDate] = useState(null);
  const [toDate, setToDate] = useState(new Date());
  const { t } = useTranslation();
  const [{ ORDER__MAIN__DATA }, dispatch] = useStateValue();
  const [data, setData] = useState([]);
  const [structuredData, setStructuredData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [orderConfirmedData, setOrderConfirmedData] = useState([]);
  const [orderCancelledData, setOrderCancelledData] = useState([]);
  const [invoiceCompletedData, setInvoiceCompletedData] = useState([]);
  const [invoiceCancelledData, setInvoiceCancelledData] = useState([]);
  const [onDeliveryData, setOnDeliveryData] = useState([]);
  const [deliveredVerifyingData, setDeliveredVerifyingData] = useState([]);
  const [deliveryCompletedData, setDeliveryCompletedData] = useState([]);
  const [deliveryFailedData, setDeliveryFailedData] = useState([]);
  const [showMenu, setShowMenu] = useState(false);

  const OrderTabs = [
    t("All Orders"),
    t("Order Confirmed"),
    t("Order Cancelled"),
    t("Invoice Completed"),
    t("Invoice Cancelled"),
    t("On Delivery"),
    t("Delivered (Verifying)"),
    t("Delivery Completed"),
    t("Delivery Failed"),
  ];

  useEffect(() => {
    const fetchData = () => {
      let Invoicedata = [];
      let COMPANYID = localStorage.getItem("companyid");
      let TOKEN = localStorage.getItem("token");
      
      // Load "All Orders" data
      ORDERMAIN(
        dispatch,
        COMPANYID,
        TOKEN,
        moment(fromDate).format("YYYY-MM-DD"),
        moment(toDate).format("YYYY-MM-DD")
      );
      
      // Set selected tab to "All Orders"
      setSelectedTab(OrderTabs[0]);
      setData(Invoicedata); // This may need to be updated to set the actual data
    };

    fetchData(); // Call the fetch function on component mount
  }, [dispatch, fromDate, toDate, t]);

  useEffect(() => {
    let Invoicedata = [];
  
    setStructuredData([Invoicedata]);
    setData(Invoicedata);
  }, [ORDER__MAIN__DATA,fromDate,toDate]);
  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  // GETTING MIN AND MAX DATE BASED ON THE ORDER__MAIN__DATA
  useEffect(() => {

    let OrderConfirmed = [];
    let OrderCancelled = [];
    let InvoiceCompleted = [];
    let InvoiceCancelled = [];
    let OnDelivery = [];
    let DeliveredVerifying = [];
    let DeliveryCompleted = [];
    let DeliveryFailed = [];
    let Invoicedata = [];

    ORDER__MAIN__DATA.forEach((order) => {
      order.ship_to.forEach((branch) => {
        let statusMap = new Map();
        branch.invoice.forEach((invoice) => {
          if (!statusMap.has(invoice.status)) {
            statusMap.set(invoice.status, []);
          }
          statusMap.get(invoice.status).push(invoice);
        });

        statusMap.forEach((invoices, status) => {
          Invoicedata.push({
            order_id: order.order_id,
            order_date: order.order_date,
            delivery_date: order.delivery_date,
            order_total: order.order_total,
            branch_name: branch.branch_name,
            invoices: invoices,
            status: order.status,
            ship_to: [{ branch_name: branch.branch_name, invoice: invoices }],
            invoice_status: invoices[0].status,
          });
        });
      });
    });
    const output = structuredClone(Invoicedata);
    for (const order of output) {
      switch (order.invoice_status) {
        case "Order Confirmed":
          OrderConfirmed.push(order);
          break;
        case "Order Cancelled":
          OrderCancelled.push(order);
          break;
        case "Invoice Completed":
          InvoiceCompleted.push(order);
          break;
        case "Invoice Cancelled":
          InvoiceCancelled.push(order);
          break;
        case "On Delivery":
          OnDelivery.push(order);
          break;
        case "Delivered (Verifying)":
          DeliveredVerifying.push(order);
          break;
        case "Delivery Completed":
          DeliveryCompleted.push(order);
          break;
        case "Delivery Failed":
          DeliveryFailed.push(order);
          break;
        default:
          break;
      }
    }
    setStructuredData(Invoicedata);
    setOrderConfirmedData(OrderConfirmed);
    setOrderCancelledData(OrderCancelled);
    setInvoiceCompletedData(InvoiceCompleted);
    setInvoiceCancelledData(InvoiceCancelled);
    setOnDeliveryData(OnDelivery);
    setDeliveredVerifyingData(DeliveredVerifying);
    setDeliveryCompletedData(DeliveryCompleted);
    setDeliveryFailedData(DeliveryFailed);
  }, [ORDER__MAIN__DATA]);

  const filterDataByDateRange = (data, startDateStr, endDateStr) => {
    const startDate = moment(startDateStr).subtract(1, "day");
    const endDate = moment(endDateStr);

    return data.filter((event) => {
      const eventDate = moment(event.order_date);
      return eventDate.isBetween(startDate, endDate, undefined, "[]");
    });
  };

  const filterOrdersByDateRange = (orders) => {
    return orders.filter((order) => {
      const orderDate = new Date(order.order_date);
      return orderDate >= fromDate && orderDate <= toDate;
    });
  };
  useEffect(() => {
    let filteredData = [];
  
    switch (selectedTab) {
      case t("Order Confirmed"):
        filteredData = orderConfirmedData;
        break;
      case t("Order Cancelled"):
        filteredData = orderCancelledData;
        break;
      case t("Invoice Completed"):
        filteredData = invoiceCompletedData;
        break;
      case t("Invoice Cancelled"):
        filteredData = invoiceCancelledData;
        break;
      case t("On Delivery"):
        filteredData = onDeliveryData;
        break;
      case t("Delivered (Verifying)"):
        filteredData = deliveredVerifyingData;
        break;
      case t("Delivery Completed"):
        filteredData = deliveryCompletedData;
        break;
      case t("Delivery Failed"):
        filteredData = deliveryFailedData;
        break;
      default:
        filteredData = structuredData;
    }
  
    filteredData = filteredData.filter(order => {
      const orderDate = new Date(order.order_date);
      return orderDate >= fromDate && orderDate <= toDate;
    });
  
    setData(filteredData.sort((a, b) => new Date(b.order_date) - new Date(a.order_date)));
  }, [selectedTab, fromDate, toDate, orderConfirmedData, orderCancelledData, invoiceCompletedData, invoiceCancelledData, onDeliveryData, deliveredVerifyingData, deliveryCompletedData, deliveryFailedData, structuredData]);
  
  
  // useEffect(() => {
  //   let dateFiltered = [];

  //   // Check if the selected tab has data
  //   if (t(selectedTab) === t("All Orders")) {
  //       dateFiltered = structuredData; // Show all data
  //   } else if (t(selectedTab) === t("Order Confirmed")) {
  //       dateFiltered = orderConfirmedData;
  //   } else if (t(selectedTab) === t("Order Cancelled")) {
  //       dateFiltered = orderCancelledData;
  //   } else if (t(selectedTab) === t("Invoice Completed")) {
  //       dateFiltered = invoiceCompletedData;
  //   } else if (t(selectedTab) === t("Invoice Cancelled")) {
  //       dateFiltered = invoiceCancelledData;
  //   } else if (t(selectedTab) === t("On Delivery")) {
  //       dateFiltered = onDeliveryData;
  //   } else if (t(selectedTab) === t("Delivered (Verifying)")) {
  //       dateFiltered = deliveredVerifyingData;
  //   } else if (t(selectedTab) === t("Delivery Completed")) {
  //       dateFiltered = deliveryCompletedData; // Only show delivery completed data
  //   } else if (t(selectedTab) === t("Delivery Failed")) {
  //       dateFiltered = deliveryFailedData;
  //   }

  //   // Filter by date range
  //   const filteredByDate = dateFiltered.filter((order) => {
  //       const orderDate = new Date(order.order_date);
  //       return orderDate >= fromDate && orderDate <= toDate;
  //   });
  //   if (filteredByDate.length === 0) {
  //     setData([]);
  // } else {
  //     setData(
  //         filteredByDate.sort(
  //             (a, b) => new Date(b.order_date) - new Date(a.order_date)
  //         )
  //     );
  // }
  // }, [ORDER__MAIN__DATA, selectedTab, fromDate, toDate]);

  useEffect(() => {
    const filteredValue = data.filter((el) => {
      const orderIdMatch = el.order_id.includes(searchOrder);
      const invoiceNoMatch = el.invoices.some((invoice) =>
        invoice.invoice_no.includes(searchOrder)
      );
      const deliveryDateMatch = el.delivery_date.includes(searchOrder);
      const branchNameMatch = el.branch_name
        .toLowerCase()
        .includes(searchOrder.toLowerCase());

      return (
        orderIdMatch || invoiceNoMatch || deliveryDateMatch || branchNameMatch
      );
    });
    setFilteredData(filteredValue);
  }, [searchOrder]);

  const dateRangerMemo = useMemo(() => {
    return (
      <DateRangePicker
      fromDate={fromDate}
      toDate={toDate}
      handleFromDateChange={handleFromDateChange}
      handleToDateChange={handleToDateChange}
      renderInput={(startProps, endProps) => (
        <>
          <input
            {...startProps}
            inputMode="none"
            readOnly
            className="custom-input"
            onFocus={(e) => e.target.blur()}
          />
          <input
            {...endProps}
            inputMode="none"
            readOnly
            className="custom-input"
            onFocus={(e) => e.target.blur()} 
          />
        </>
      )}
    />
    
    );
  }, [fromDate, toDate, t, showMenu]);

  const settings = {
    dots: false,
    infinite: false,
    autoplay: false,
    speed: 500,
    slidesToShow: 2,
    initialSlide: 0,
    swipeToSlide: true,
  };
  const handleApply = () => {
    setFromDate(tempFromDate);
    setToDate(tempToDate);
  };

  const handleCancel = () => {
    setTempFromDate(fromDate);
    setTempToDate(toDate);
  };
  return (
    <div className="ORDERPAGE">
      <div className="ORDERPAGE__BREADCRUMBS">
        <p>
          <Link to="/">{t("Home")}</Link>
          <span>{t("MYORDER")}</span>
        </p>
      </div>

      {(window.innerWidth > 912 || localStorage.getItem("device") !== 'true') && (
        <h3 className="ORDERPAGE__TITLE">
          {window.innerWidth <= 912 && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <g clip-path="url(#clip0_1_15096)">
                <path
                  d="M17.5098 3.86961L15.7298 2.09961L5.83984 11.9996L15.7398 21.8996L17.5098 20.1296L9.37984 11.9996L17.5098 3.86961Z"
                  fill="#1C304A"
                  fill-opacity="0.52"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_15096">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          )}
          {t("My Order")}
        </h3>
      )}
      <div className="ORDERPAGE__TABS">
        {window.innerWidth <= 912 ? (
          <>
            {OrderTabs?.map((OT, i) => (
              <div key={i}>
                <p
                  className={`${selectedTab === OT && "currentTab"}`}
                  onClick={() => setSelectedTab(OT)}
                >
                  <span>{OT}</span>
                  <span className="count">
                  </span>
                </p>
              </div>
            ))}
          </>
        ) : (
          OrderTabs?.map((OT, i) => (
            <div>
              <p
                className={`${selectedTab === OT && "currentTab"}`}
                onClick={() => setSelectedTab(OT)}
                key={i}
              >
                <span>{t(OT)}</span>
                <span className="count">
                </span>
              </p>
            </div>
          ))
        )}
      </div>

      {window.innerWidth <= 912 && <hr />}

      <div className="ORDERPAGE__SEARCH__DATE">
        {!showMenu && (
          <label>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
            >
              <g clip-path="url(#clip0_2404_5813)">
                <path
                  d="M15.875 14H15.085L14.805 13.73C15.785 12.59 16.375 11.11 16.375 9.5C16.375 5.91 13.465 3 9.875 3C6.285 3 3.375 5.91 3.375 9.5C3.375 13.09 6.285 16 9.875 16C11.485 16 12.965 15.41 14.105 14.43L14.375 14.71V15.5L19.375 20.49L20.865 19L15.875 14ZM9.875 14C7.385 14 5.375 11.99 5.375 9.5C5.375 7.01 7.385 5 9.875 5C12.365 5 14.375 7.01 14.375 9.5C14.375 11.99 12.365 14 9.875 14Z"
                  fill="#1C304A"
                  fill-opacity="0.52"
                />
              </g>
              <defs>
                <clipPath id="clip0_2404_5813">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="translate(0.375)"
                  />
                </clipPath>
              </defs>
            </svg>
            <input
              type="text"
              placeholder={`${t("Search Order")}`}
              value={searchOrder}
              onChange={(e) => {
                setSearchOrder(e.target.value);
              }}
            />
          </label>
        )}
        {showMenu && dateRangerMemo}
        {window.innerWidth > 912 ? (
          dateRangerMemo
        ) : showMenu ? (
          <svg
            onClick={() => setShowMenu(!showMenu)}
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <g clip-path="url(#clip0_2404_5813)">
              <path
                d="M15.875 14H15.085L14.805 13.73C15.785 12.59 16.375 11.11 16.375 9.5C16.375 5.91 13.465 3 9.875 3C6.285 3 3.375 5.91 3.375 9.5C3.375 13.09 6.285 16 9.875 16C11.485 16 12.965 15.41 14.105 14.43L14.375 14.71V15.5L19.375 20.49L20.865 19L15.875 14ZM9.875 14C7.385 14 5.375 11.99 5.375 9.5C5.375 7.01 7.385 5 9.875 5C12.365 5 14.375 7.01 14.375 9.5C14.375 11.99 12.365 14 9.875 14Z"
                fill="#1C304A"
                fill-opacity="0.52"
              />
            </g>
            <defs>
              <clipPath id="clip0_2404_5813">
                <rect
                  width="24"
                  height="24"
                  fill="white"
                  transform="translate(0.375)"
                />
              </clipPath>
            </defs>
          </svg>
        ) : (
          <svg
            onClick={() => setShowMenu(!showMenu)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g clip-path="url(#clip0_1_14970)">
              <path
                d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z"
                fill="#192739"
                fill-opacity="0.94"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_14970">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
      </div>

      <div className="ORDERPAGE__BLOCK">
        {filteredData.length > 0 ? (
          filteredData.map((el) => (
          <MainBlock
            key={el.order_id}
            delivery_date={el.delivery_date}
            order_date={el.order_date}
            order_id={el.order_id}
            order_total={el.order_total}
            status={el.status}
            ship_to={el.ship_to}
          />
        ))
      ) : data.length > 0 ? (
        data.map((el) => (
          <MainBlock
            key={el.order_id}
            delivery_date={el.delivery_date}
            order_date={el.order_date}
            order_id={el.order_id}
            order_total={el.order_total}
            status={el.status}
            ship_to={el.ship_to}
          />
        ))
      ) : (
        <p>{`No Data in ${selectedTab} tab`}</p>
      )}

      </div>
    </div>
  );
}

export default OrderPage;